<template>
  <b-container class="m-0 p-0" v-if="currentEntry.activityType_needInputs">
    <h4 class="subtitle-form overload-subtitle mb-2">Motif d'intervention</h4>

    <div v-if="currentEntry.reason === undefined" class="text-activity-notes text-left">
      {{ $t("activities.multiple.details.reason") }}
    </div>
    <div v-else-if="currentEntry.reason === null" class="text-activity-notes empty-attr text-left">
      Aucun motif d'intervention renseigné
    </div>
    <div v-else class="text-infos bg-white">
        <span>{{ currentEntry.reason_designation }}</span>
    </div>

    <h4 class="subtitle-form overload-subtitle mb-2 mt-3">Cibles de l'intervention</h4>
    <div v-if="currentEntry.target_list == undefined" class="text-activity-notes text-left">
      {{ $t("activities.multiple.details.targets") }}
    </div>
    <div v-else-if="currentEntry.target_list.length == 0" class="text-activity-notes empty-attr text-left">
      Aucune cible d'intervention renseignée
    </div>
    <div v-else>
      <span v-for="target in currentEntry.target_list" v-bind:key="target.id" :value="target.id">
        <div class="text-infos bg-white"> {{ target.designation }} </div>
      </span>
    </div>
      <hr/>
  </b-container>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],
  name: "detail-reason-targets-intervention",
};
</script>

<style lang="scss" scoped>
/**
 * Overload subtitle-
 */
.overload-subtitle {
  margin: 0px 0px 0px 0px;
}

/**
 * Color text for empty list.
 */
.empty-attr {
  text-align: center;
  color: $danger-color;
}
</style>
